// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("chosen.jquery.min.js") // a 3rd party jQuery plugin for JS multiple dropdown selections

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:

//document.addEventListener("DOMContentLoaded", function() {
  var $html = $('html');
  $html.on('click.ui.dropdown', '.js-dropdown', function(e) {
  if ($(e.target).is('.js-dropdown__current')) {
    e.preventDefault();
    $(this).toggleClass('is-open');
  }
  });

  $html.on('click.ui.dropdown', '.js-dropdown [data-dropdown-value]', function(e) {
  e.preventDefault();
  var $item = $(this);
  var $dropdown = $item.parents('.js-dropdown');

  });

  $html.on('click.ui.dropdown', function(e) {
  var $target = $(e.target);
  if (!$target.parents().hasClass('js-dropdown')) {
    $('.js-dropdown').removeClass('is-open');
  }
  });

  // Google A.
  var loadGA = function(){
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  }

  _doNotTrack = (
  window.doNotTrack == "1" ||
  navigator.doNotTrack == "yes" ||
  navigator.doNotTrack == "1" ||
  navigator.msDoNotTrack == "1" ||
  ('msTrackingProtectionEnabled' in window.external &&
  window.external.msTrackingProtectionEnabled())
  );
  if (!_doNotTrack) {
  loadGA();

  ga('create', "UA-132598879-2", 'auto');
  ga('set', 'anonymizeIp', true);
  ga('send', 'pageview');

  }else {
  console.log("DoNotTrack enabled!");
  }

  (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1686292,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');


  //For stop and run loading animation
  function runProcessingAnimation(button) {
    $("#processing-data").removeClass('hidden');
    button.hide();
  }

  function stopProcessingAnimation(button) {
    button.show();
    $("#processing-data").addClass('hidden');
  }

  var buttonWithAnimation = $('.js-button-with-animation')
  buttonWithAnimation.on('click', function(){
    runProcessingAnimation(buttonWithAnimation)
  });